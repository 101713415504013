html {
  font-family: sans-serif;
}

body {
  margin: auto;
  max-width: 777px;
}

header {
  align-items: center;
  background-color: #0060A9;
  display: flex;
  padding: 20px;
}

.title {
  color: #FFF;
  font-size: 24px;
  line-height: 1;
  margin: 0;
}

.logo {
  background-color: #C75C5C;
  border-radius: 50%;
  float: left;
  height: 48px;
  margin-right: 10px;
  width: 48px;
}

.trouble-item {
  display: flex;
  margin: 10px 0 10px 10px;
}

.trouble-item:not(:last-child) .trouble-info {
  border-bottom: 1px solid #DEDEDE;
}

.line {
  align-items: center;
  border-radius: 50%;
  color: #FFF;
  display: inline-flex;
  font-weight: bold;
  justify-content: center;
  line-height: 1;
  text-align: center;
  vertical-align: middle;
}

.line--large {
  font-size: 20px;
  height: 20px;
  margin-right: 10px;
  min-width: 20px;
  padding: 5px;
  text-align: center;
}

.line--small {
  font-size: 12px;
  height: 10px;
  min-width: 10px;
  padding: 4px;
}

.line--1,
.line--2,
.line--3 {
  background-color: #EE352E;
}

.line--4,
.line--5,
.line--6 {
  background-color: #00933C;
}

.line--7 {
  background-color: #B933AD;
}

.line--A,
.line--C,
.line--E {
  background-color: #2850AD;
}

.line--B,
.line--D,
.line--F,
.line--M {
  background-color: #F66319;
}

.line--G {
  background-color: #6CBE45;
}

.line--J,
.line--Z {
  background-color: #996633;
}

.line--N,
.line--Q,
.line--R,
.line--W {
  background-color: #FCD117;
  color: #000;
}

.line--L {
  background-color: #A7A9AC;
}

.line--S {
  background-color: #808183;
}

.line--SIR {
  background-color: #333399;
}

.line--SIR.line--large {
  font-size: 12px;
}

.line--SIR.line--small {
  font-size: 12px;
}

.trouble-info {
  flex: auto;
  line-height: 1.5;
  padding-bottom: 10px;
}

.trouble-status {
  color: #333;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.trouble-description  {
  color: #666;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 10px;
}